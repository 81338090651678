/**
 * Generates an array of options for a select dropdown component.
 * @param {number} min - The minimum value (inclusive).
 * @param {number} max - The maximum value (inclusive).
 * @returns {Array<{value: string, label: string}>} An array of option objects.
 */
export const generateSelectOptions = (min, max) => {
  const options = [];
  for (let i = min; i <= max; i++) {
    options.push({
      value: i.toString(),
      label: i.toString(),
    });
  }
  return options;
};