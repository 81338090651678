import { useEffect, useMemo, useRef, useState } from 'react'
import { useLoaderData, useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { z } from 'zod'
import { StatusCodes } from 'http-status-codes'
import { useForm, zodResolver } from '@mantine/form'
import { notifications } from '@mantine/notifications'
import {
  Container,
  Select,
  SimpleGrid,
  TextInput,
  Text,
  Title,
  Code,
  Stack,
  rem,
  ActionIcon,
  Button,
  createStyles,
  Card,
  Modal,
  Flex,
  Box,
  Checkbox,
  Loader,
  Autocomplete,
  Paper,
} from '@mantine/core'
import { DateInput, TimeInput } from '@mantine/dates'
import { useDisclosure, useToggle } from '@mantine/hooks'
import {
  IconCheck,
  IconClock,
  IconCurrentLocation,
  IconCurrentLocationOff,
  IconExclamationCircle,
} from '@tabler/icons-react'

import fetchService from '../../services/fetch.service'
import { ActionLinkToTickets } from './shared/action-link-to-tickets'
import viewClasses from './tickets.module.scss'
import { FormHeader } from '../../common/form-header'
import weatherService from '../../services/weather.service'
import { generateTicketId } from '../../utils/generate-ticket-uid'
import { getInputSectionText } from '../../common/input-section-text'
import { SectionHeader } from '../../common/section-header'
import { ErrorSection } from '../../common/error-section'
import { SubmitButton } from '../../common/submit-button'
import { DraftButton } from '../../common/draft-button'
import { CEMENT_TYPES, TICKET_STATUSES, parseInputFixed } from './helper'
import { TicketStatusBadge } from './shared/ticket-status-badge'
import browserStorageService, { STORAGE_KEY } from '../../services/browser-storage.service'
import { generateSelectOptions } from '../../utils/form'
import { useNavbarStore } from '../../stores/navbar.store'


const useStyles = createStyles((theme) => ({
  button: {
    borderRadius: theme.radius.sm,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
    cursor: 'pointer',
    height: rem('3.25rem'),
    maxWidth: rem('13rem'),
  },
}))

/**
 *  
 */

export const transpile = (importedTicketSpec = null) => {
  if (!importedTicketSpec || importedTicketSpec === undefined) return {}

  const {
    // admixtures,
    // aggregates,
    // calculated,
    // company,
    // flags,
    inputs,
    // math,
    location,
    meta,
    project,
    refs,
    // submittedBy,
    // modifiedBy,
    // approvedBy,
    // truck,
    // truckConfig,
  } = importedTicketSpec

  const latLongRaw = !!location
    ? location?.position?.latLongRaw || ''
    : ''

  const projectDate = !!project.date ? dayjs.unix(project.date) : dayjs(),
    projectDateFormatted = projectDate.startOf('date').toDate()

  return {
    ticketValues: {
      assignedTo: refs.assignedTo || '',
      truck: refs.truck || '',
      design: refs.design || '',
      uuid: meta.ticketUid || generateTicketId(),
      location: {
        id: refs.location || '',
        zipcode: project.zipcode || '',
        locationService: !!location,
        latitude: !!location ? latLongRaw.split(',')[0] : '',
        longitude: !!location ? latLongRaw.split(',')[1] : '',
      },
      project: {
        name: meta.name || '',
        id: project.projectId || '',
        number: project.projectNumber || '',
        purchaseOrder: project.purchaseOrder || '',
        location: project.location || '',
        date: projectDateFormatted,
        startTime: project.startTime,
        endTime: project.endTime,
        sequence: ''+project.sequence,
      },
    },
    confirmationValues: {},
    inputValues: {
      cementCount: inputs.cementTotal,
      waterTotal: inputs.waterTotal,
      specialtyTotal: inputs.specialtyTotal,
    },
  }
}

const ticketLocationSchema = z.object({
  zipcode: z.coerce.string().min(5, { message: 'You must specify a zipcode' }),
})

const ticketProjectSchema = z.object({
  name: z.string().min(1, { message: 'Missing project name' }),
  id: z.string().min(1, { message: 'Missing project id' }),
  number: z.string().min(1, { message: 'Missing project number' }),
  location: z.string().min(1, { message: 'Missing job location' }),
  purchaseOrder: z.string().min(1, { message: 'Missing purchase order number' }),
  date: z.coerce.date(),
  sequence: z.string().min(1, { message: 'Missing truck sequence' }),
  // startTime: z.coerce.string().min(1, { message: 'Must specify a start time' }),
  // endTime: z.coerce.string().min(1, { message: 'Must specify an end time' }),
})

const ticketSchema = z.object({
  truck: z.coerce.string().min(1, { message: 'You need to select a truck' }),
  design: z.coerce.string().min(1, { message: 'You need to select a concrete design' }),
  uuid: z.coerce.string().min(1, { message: 'Missing generated ticket ID' }),
  location: ticketLocationSchema,
  project: ticketProjectSchema,
})

const didConfirmAllConfigs = (values) => {
  if (!values || !values.length || !Array.isArray(values)) return null
  return values.some(({ confirmed }) => !confirmed)
    ? `You did not confirm ${values.length > 1 ? 'some of the' : 'the'} configuration${values.length > 1 ? 's' : ''}`
    : null
}

/**
 * TODO - Autosave draft
 * @returns 
 */

export const ManageTicketView = () => {
  const importedTicket = useLoaderData()
  const { classes } = useStyles()
  const navigate = useNavigate()
  const { isDesktop, desktopNavWidth, mobileNavWidth } = useNavbarStore()

  const ticketForm = useForm({
    // validateInputOnBlur: true,
    // validate: zodResolver(schema),
    validate: zodResolver(ticketSchema.required()),
    initialValues: {
      truck: '',
      design: '',
      uuid: generateTicketId(),
      assignedTo: '',
      location: {
        id: '',
        zipcode: '',
        locationService: false, 
        latitude: '',
        longitude: '',
      },
      project: {
        name: '',
        id: '',
        number: '',
        purchaseOrder: '',
        location: '',
        date: '', //new Date(),// '2023-10-02T05:00:00.000Z',
        startTime: '', //'12:04',
        endTime: '',
        sequence: '',
      },
    },
  })

  const confirmationForm = useForm({
    initialValues: {
      aggregates: [],
      admixtures: [],
    },
    validate: {
      aggregates: didConfirmAllConfigs,
      admixtures: didConfirmAllConfigs,
    },
  })

  const manualEntryForm = useForm({
    initialValues: {
      cementCount: '',
      waterTotal: '',
      specialtyTotal: '',
    },
    validate: {
      cementCount: (val) => isNaN(parseFloat(val)) ? 'Cement counts required' : null,
      waterTotal: (val) => isNaN(parseFloat(val)) ? 'Water total required' : null,
      specialtyTotal: (val) => isNaN(parseFloat(val)) ? 'Specialty total required' : null,
    },
  })

  const { getInputProps: getTicketInputProps } = ticketForm,
    { getInputProps: getConfirmationInputProps } = confirmationForm,
    { getInputProps: getManualEntryInputProps } = manualEntryForm,
    [showDebug, toggleDebug] = useToggle([false, true]),
    [submitting, setSubmitting] = useState(false),
    notificationId = {
      upload: 'ticket-manage-upload',
    },
    params = useParams(),
    unloadTimeRef = useRef(),
    finishTimeRef = useRef(),
    [configuredTrucks, setConfiguredTrucks] = useState([]),
    [availableDesigns, setAvailableDesigns] = useState([]),
    [activeTruck, setActiveTruck] = useState(null),
    [activeDesign, setActiveDesign] = useState(null),
    designLoaded = !!activeDesign,
    [locationDisclosureOpen, { open: openLocationDisclosure, close: closeLocationDisclosure }] = useDisclosure(false),
    [locationPrompt, setLocationPrompt] = useState({}),
    [fetchingLocation, toggleFetchingLocation] = useToggle([false, true]),
    [locationError, setLocationError] = useState(false),
    [locationDynamic, setLocationDynamic] = useState(false),
    [error, setError] = useState(null),
    [loadedTicket, setLoadedTicket] = useState(null),
    [previousJobLocations, setPreviousJobLocations] = useState([]),
    [assignable, setAssignable] = useState([])

  // load an imported ticket if provided
  useEffect(() => {
    if (importedTicket) {
      const {
        ticketValues,
        confirmationValues,
        inputValues,
      } = transpile(importedTicket)

      setLoadedTicket({
        status: importedTicket?.meta?.status || '',
        ticketValues,
        inputValues,
      })

      if (ticketValues.location.locationService) {
        setLocationDynamic(true)
        toggleFetchingLocation(false)
      }

      ticketForm.setValues(ticketValues)
      manualEntryForm.setValues(inputValues)
    } else {
      console.log('NO TICKET IMPORT ... set defaults')
      const nowDate = dayjs(),
        nowDateFormatted = nowDate.startOf('date').toDate(),
        nowTimeFormatted = nowDate.format('HH:mm')

      ticketForm.setFieldValue('project.date', nowDateFormatted)
      ticketForm.setFieldValue('project.startTime', nowTimeFormatted)
      handleLoadLocation({ skipPrompt: true })
    }
  }, [importedTicket])

  // load configured trucks (designs >= 1)
  useEffect(() => {
    const fetchTrucks = async () => {
      return await fetchService.get('/auth/query?model=trucks&designs=gt1')
    }

    fetchTrucks()
      .then(({ result }) => {
        const trucks = result?.trucks || []
        setConfiguredTrucks(trucks)
      })
      .catch((err) => {
        console.warn('Unable to fetch trucks', err)
      })

    return () => {
      console.info('Form reset')
      ticketForm.reset()
      confirmationForm.reset()
      manualEntryForm.reset()
    }
  }, [])

  // load previous job locations
  useEffect(() => {
    const fetchJobLocations = async (companyId) => {
      return await fetchService.get(`/auth/companies/${companyId}/locations`)
    }

    const fetchCompanyUsers = async (companyId) => {
      return await fetchService.get(`/auth/companies/${companyId}/users`)
    }

    browserStorageService.getItem(STORAGE_KEY.SESSION)
      .then((session) => {
        fetchJobLocations(session.company.companyId || session.refs.company)
          .then(({ result }) => {
            const locations = result?.locations || []
            setPreviousJobLocations(locations)
          })
          .catch((err) => {
            console.warn('Unable to load previous job locations', err)
          })

        fetchCompanyUsers(session.company.companyId || session.refs.company)
          .then(({ result }) => {
            const users = result?.users || []
            setAssignable(users)
          })
          .catch((err) => {
            console.warn('Unable to load assignable users', err)
          })
      })
  }, [])

  // generate edit mode status
  const isEditMode = useMemo(() => {
    return (importedTicket !== null && importedTicket !== undefined) && !!params.ticketId
  }, [importedTicket, params])

  const validateTicket = () => {
    [ticketForm, confirmationForm, manualEntryForm].forEach((form) => form.validate())
    let ticketValid = ticketForm.isValid(),
      confirmValid = confirmationForm.isValid(),
      inputsValid = manualEntryForm.isValid()

    // verify calculations are good
    let calculationsReady = false
    if (inputsValid) {
      calculationsReady = !isNaN(parseFloat(calculatedTotalConcretePoured)) &&
        !isNaN(parseFloat(calculatedWaterPerYard)) &&
        !isNaN(parseFloat(calculatedSpecialtyPerYard))
    }

    return ticketValid && confirmValid && inputsValid
  }

  const uploadTicket = async (status = TICKET_STATUSES.DRAFT) => {
    const ticket = {
      meta: ticketForm.values,
      design: activeDesign,
      confirmations: confirmationForm.values,
      inputs: manualEntryForm.values,
      calculations: {
        calculatedTotalConcretePoured,
        calculatedWaterPerYard,
        calculatedSpecialtyPerYard,
      },
    }

    try {
      const body = isEditMode
        ? { status, ...ticket }
        : { status, ticket }
      const dynamicRequest = isEditMode
        ? fetchService.patch(`/auth/ticket/${params.ticketId}`, { body })
        : fetchService.post('/auth/ticket', { body })
      const response = await dynamicRequest

      if (response.status === StatusCodes.OK) {
        const {
          result,
        } = response

        notifications.update({
          id: notificationId.upload,
          color: 'teal',
          title: 'Done',
          message: `Your ticket was ${isEditMode ? 'updated' : 'uploaded'} successfully`,
          icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
          loading: false,
        })

        navigate('/dashboard/tickets')
      }
    } catch (err) {
      console.warn('Failed to upload ticket', err)

      setSubmitting(false)
      notifications.update({
        id: notificationId.upload,
        color: 'red',
        title: `Unable to ${isEditMode ? 'update' : 'upload'} ticket`,
        message: `We encountered an error ${isEditMode ? 'updating' : 'uploading'} your ticket. Please try again or contact support.`,
        icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
        autoClose: false,
      })
    }
  }

  /**
   * Save current ticket as "DRAFT"
   */
  const saveTicket = () => {
    setError('')
    setSubmitting(true)

    notifications.show({
      id: notificationId.upload,
      loading: true,
      title: 'Saving Ticket as Draft',
      autoClose: false,
      withCloseButton: false,
    })

    uploadTicket(TICKET_STATUSES.DRAFT)
  }

  /**
   * Save current ticket as "IN_REVIEW", runs validations
   */
  const submitTicket = () => {
    if (!validateTicket()) {
      setError('There was some invalid information found, please correct and resubmit or Save as a Draft')
      console.warn('Unable to submit form', {
        errors: {
          ticket: ticketForm.errors,
          confirmations: confirmationForm.errors,
          input: manualEntryForm.errors
        }
      })
      return
    }

    setError('')
    setSubmitting(true)
    
    let title = 'Publishing Ticket for Approval'
    if (isEditMode && loadedTicket?.status === TICKET_STATUSES.DRAFT) {
      title = 'Submitting ticket for approval'
    } else if (isEditMode) {
      title = 'Publishing update for ticket'
    }

    notifications.show({
      id: notificationId.upload,
      loading: true,
      title,
      autoClose: false,
      withCloseButton: false,
    })

    uploadTicket(TICKET_STATUSES.IN_REVIEW)
  }

  // clear location field values
  const clearLocationFields = () => {
    ticketForm.setFieldValue('location.id', '')
    ticketForm.setFieldValue('location.zipcode', '')
    ticketForm.setFieldValue('location.locationService', false)
  }

  // adjust ticket state for a failed location request
  const onLocationFailure = () => {
    toggleFetchingLocation(false)
    setLocationDynamic(false)
    setLocationError(true)
    clearLocationFields()
  }

  const handleCloseLocationDisclosure = () => {
    closeLocationDisclosure()
    setLocationPrompt({})
    toggleFetchingLocation(false)
  }

  const onLocationGranted = async (position) => {
    closeLocationDisclosure()

    const { coords: { latitude, longitude } } = position
    ticketForm.setFieldValue('location.latitude', latitude)
    ticketForm.setFieldValue('location.longitude', longitude)

    try {
      // const pointResponse = await weatherService.getPoints(coords.latitude, coords.longitude)
      // const forecast = await weatherService.getHourly(pointResponse.forecastHourly)
      // const observation = await weatherService.getZoneObservations(pointResponse.forecastZone)
      // console.log({
      //   forecast,
      //   observation,
      // })

      const response = await weatherService.fetchLocation(latitude, longitude)
      const { result: { location } } = response
      ticketForm.setFieldValue('location.id', location.id || '-1')
      ticketForm.setFieldValue('location.zipcode', location.location.zipcode)
      ticketForm.setFieldValue('location.locationService', true)
      setLocationDynamic(true)
      toggleFetchingLocation(false)
    } catch (err) {
      onLocationFailure()
    }
  }

  const onLocationDenied = () => {
    setLocationPrompt({
      status: 'denied',
      messages: [
        `You have chosen to Deny or Reject Location Permissions.`,
        `In the future, you will have to manually type in the zipcode of the jobsite or
        manually adjust your browser settings for this website.`,
        `Please contact your supervisor or ECONCRETELY support for help.`,
      ],
    })
    openLocationDisclosure()
  }

  /**
   * Click event on disable location icon
   */
  const handleDisableLocation = () => {
    clearLocationFields()
    setLocationDynamic(false)
    setLocationError(false)
  }

  /**
   * Request lat/long permission from geolocation
   */
  const promptLocationPermission = () => {
    setLocationError(false)
    navigator.geolocation.getCurrentPosition(
      onLocationGranted,
      onLocationDenied,
      {
        enableHighAccuracy: true,
      },
    )
  }

  /**
   * Click event on location icon
   */
  const handleLoadLocation = async ({ skipPrompt = false }) => {
    toggleFetchingLocation(true)
    clearLocationFields()

    try {
      const geoQuery = await navigator.permissions.query({ name: 'geolocation' })
      if (!geoQuery || !geoQuery.state) {
        throw new Error(`Unable to process geolocation query ... response:${JSON.stringify(geoQuery)}`)
      }

      const { state: permission } = geoQuery
      if (permission === 'granted') {
        promptLocationPermission()
      } else if (permission === 'prompt' && !skipPrompt) {
        setLocationPrompt({
          status: 'prompt',
          messages: [
            `You have not previously granted location permissions. Clicking the "Accept" button below
            will trigger a browser prompt to appear. You must accept this prompt so we can view your current
            location.`,
            `Granting permissions once will allow us to load your current location in future tickets.
            If you reject this prompt, you will have to manually give us your permission in the future by adjusting
            your browser settings for this website.`
          ],
        })
        openLocationDisclosure()
      } else if (permission === 'denied' && !skipPrompt) {
        setLocationPrompt({
          status: 'denied',
          messages: [
            `You have previously rejected a location permission prompt. You will need to manually adjust your
            browser settings for this website for us to view your current location.`,
            `Please contact your supervisor or ECONCRETELY support for help.`,
          ],
        })
        openLocationDisclosure()
      } else if (skipPrompt) {
        toggleFetchingLocation(false)
      }
    } catch (err) {
      console.warn(err)
    }
  }

  /**
   * Generate selection for trucks
   */
  const truckOptions = useMemo(() => {
    return configuredTrucks.map((truck) => ({
      label: truck.name || `Unnamed ID:${truck.truckId}`,
      value: truck.truckId,
      group: 'Configured trucks',
    }))
  }, [configuredTrucks])

  /**
   * Generate selection for designs (within activeTruck)
   */
  const designOptions = useMemo(() => {
    if (!activeTruck) return []

    return activeTruck.configurations.map(({ design }) => ({
      label: design.meta.name
          ? `${design.meta.name} (Mix #: ${design.meta.mixDesignNumber})`
          : design.meta.mixDesignNumber,
      value: design.id,
      group: 'Published designs',
    }))
  }, [activeTruck])

  const allTruckDesigns = useMemo(() => {
    if (!activeTruck) return []

    return activeTruck.configurations.map((config) => {
      return {
        ...config.design,
        truckConfig: {
          id: config.id,
          aggregates: config.aggregates,
          admixtures: config.admixtures,
          cement: config.cement,
        },
      }
    })
  }, [activeTruck])

  // set internal active truck based on ticketForm truck changing
  useEffect(() => {
    if (!configuredTrucks || !configuredTrucks.length) setActiveTruck(null)

    if (ticketForm.values.truck) {
      const truck = configuredTrucks.find((t) => t.truckId === ticketForm.values.truck)
      setActiveTruck(truck)
      if (!loadedTicket) {
        ticketForm.setFieldValue('design', '')
      }
    } else {
      setActiveTruck(null)
    }
  }, [configuredTrucks, ticketForm.values.truck, loadedTicket])

  // set internal active design based on ticketForm design changing
  useEffect(() => {
    const formValue = !!ticketForm.values.design
        ? ticketForm.values.design
        : null,
      loadedTicketValue = !!loadedTicket && !!loadedTicket.ticketValues.design
        ? loadedTicket.ticketValues.design
        : null,
      designId = !!loadedTicketValue 
        ? loadedTicketValue
        : !!formValue
          ? formValue
          : ''

    if (designId) {
      const design = allTruckDesigns.find((d) => d.id === designId)
      setActiveDesign(design)
      confirmationForm.reset()
      if (!loadedTicket) {
        manualEntryForm.reset()
      }
    } else {
      setActiveDesign('')
      confirmationForm.reset()
    }
  }, [allTruckDesigns, ticketForm.values.design, loadedTicket])

  // set confirmationForm inputs for aggregates/admixtures loaded from design
  useEffect(() => {
    if (activeDesign) {
      if (!confirmationForm.values.aggregates.length) {
        activeDesign.aggregates.forEach((agg) => {
          confirmationForm.insertListItem('aggregates', {
            uid: agg.uid,
            name: agg.name,
            confirmed: true,
          })
        })
      }

      if (!confirmationForm.values.admixtures.length) {
        activeDesign.admixtures.forEach((admx) => {
          confirmationForm.insertListItem('admixtures', {
            uid: admx.uid,
            name: admx.name,
            confirmed: true,
          })
        })
      }
    } else {
      confirmationForm.reset()
    }
  }, [activeDesign])

  // generated cement type label
  const cementType = useMemo(() => {
    if (!activeDesign) return ''
    if (activeDesign.cement.type === 'other') {
      return activeDesign.cement.customType
    }

    const label = CEMENT_TYPES[activeDesign.cement.type]
    return !!label ? label : '<UNKNOWN_CEMENT_TYPE>'
  }, [activeDesign])

  /**
   * @deprecated
   * handle confirmation toggle for aggregates/admixtures
   */
  const setConfirmation = (type, index) => (event) => {
    const checked = confirmationForm.values[type][index]?.confirmed || false
    confirmationForm.setFieldValue(`${type}.${index}.confirmed`, !checked)
  }

  // generated aggregate cards
  const aggregates = useMemo(() => {
    if (!activeDesign || !activeDesign?.aggregates || !confirmationForm.values.aggregates) return []

    const {
      aggregates = [],
      truckConfig: { aggregates: configAggregates = [] },
     } = activeDesign

     const {
      values: { aggregates: formAggregates }
     } = confirmationForm

    return formAggregates.map((formAgg, idx) => {
      const agg = aggregates.find((_agg) => _agg.uid === formAgg.uid),
        aggConfig = configAggregates.find((_agg) => _agg.uid === formAgg.uid)

      return (
        <Card key={idx} withBorder>
          <Box mb="md">
            <Text size="xs" color="dimmed">Name</Text>
            <Text fw={500} size="sm">{agg.name}</Text>
          </Box>

          <Box mb="md">
            <Text size="xs" color="dimmed">Type</Text>
            <Text fw={500} size="sm">{agg.type}</Text>
          </Box>

          <Box mb="md">
            <Text size="xs" color="dimmed">Truck Gate Setting</Text>
            <Text fw={500} size="sm">{aggConfig?.gateSetting || 'Unable to load configuration'}</Text>
          </Box>

          <Box mb="md">
            <Text size="xs" color="dimmed">Truck Moisture Factor</Text>
            <Text fw={500} size="sm">{aggConfig?.moistureFactor || 'Unable to load configuration'}</Text>
          </Box>

          <Checkbox
            mt="lg"
            label="I have confirmed this Aggregate configuration"
            defaultChecked
            {...confirmationForm.getInputProps(`aggregates.${idx}.confirmed`)}
          />
        </Card>
      )
    })
  }, [activeDesign, confirmationForm.values.aggregates])

  // generated admixture cards
  const admixtures = useMemo(() => {
    if (!activeDesign || !activeDesign?.admixtures || !confirmationForm.values.admixtures) return []

    const {
      admixtures = [],
      truckConfig: { admixtures: configAdmixtures = [] },
     } = activeDesign

     const {
      values: { admixtures: formAdmixtures }
     } = confirmationForm

    return formAdmixtures.map((formAdmx, idx) => {
      const admx = admixtures.find((_admx) => _admx.uid === formAdmx.uid),
        admxConfig = configAdmixtures.find((_admx) => _admx.uid === formAdmx.uid)

      return (
        <Card key={idx} withBorder>
          <Box mb="md">
            <Text size="xs" color="dimmed">Name</Text>
            <Text fw={500} size="sm">{admx.name}</Text>
          </Box>

          <Box mb="md">
            <Text size="xs" color="dimmed">Dosage Rate</Text>
            <Text fw={500} size="sm">{admx.dosageRate}</Text>
          </Box>

          <Box mb="md">
            <Text size="xs" color="dimmed">Truck Calibrated Flow</Text>
            <Text fw={500} size="sm">{admxConfig?.calibratedFlow || 'Unable to load configuration'}</Text>
          </Box>

          <Checkbox
            mt="lg"
            label="I have confirmed this Admixture configuration"
            defaultChecked
            {...confirmationForm.getInputProps(`admixtures.${idx}.confirmed`)}
          />
        </Card>
      )
    })
  }, [activeDesign, confirmationForm.values.admixtures])

  // generated icon for location services
  const locationIcon = useMemo(() => {
    if (fetchingLocation) {
      return (<Loader size="xs" />)
    }

    if (locationDynamic) {
      return (
        <ActionIcon color="green" onClick={handleDisableLocation}>
          <IconCurrentLocationOff size={rem(16)} stroke={1.5} />
        </ActionIcon>
      )
    }

    return (
      <ActionIcon onClick={handleLoadLocation}>
        <IconCurrentLocation size={rem(16)} stroke={1.5} />
      </ActionIcon>
    )
  }, [fetchingLocation, locationDynamic])

  const calculatedTotalConcretePoured = useMemo(() => {
    if (!activeDesign || !manualEntryForm.values.cementCount) return ''

    const inputCementCount = parseInputFixed(manualEntryForm.values.cementCount),
      truckCementCountPerYard = parseInputFixed(activeDesign.truckConfig.cement.count)

    // console.log({
    //   inputs: [manualEntryForm.values.cementCount, activeDesign.truckConfig.cement.count],
    //   parsed: [inputCementCount, truckCementCountPerYard]
    // })

    return (inputCementCount / truckCementCountPerYard).toFixed(4)
  }, [activeDesign, manualEntryForm.values.cementCount])

  const calculatedWaterPerYard = useMemo(() => {
    if (!activeDesign || !manualEntryForm.values.waterTotal) return ''

    const inputWaterTotal = parseInputFixed(manualEntryForm.values.waterTotal),
      totalConcretePoured = parseInputFixed(calculatedTotalConcretePoured)

    return (inputWaterTotal / totalConcretePoured).toFixed(4)
  }, [manualEntryForm.values.waterTotal, calculatedTotalConcretePoured])

  const calculatedSpecialtyPerYard = useMemo(() => {
    if (!activeDesign || !manualEntryForm.values.specialtyTotal) return ''

    const inputSpecialtyTotal = parseInputFixed(manualEntryForm.values.specialtyTotal),
      totalConcretePoured = parseInputFixed(calculatedTotalConcretePoured)

    // console.log({
    //   inputs: [manualEntryForm.values.cementCount, activeDesign.cementCount],
    //   parsed: [inputCementCount, truckCementCountPerYard]
    // })

    return (inputSpecialtyTotal / totalConcretePoured).toFixed(4)
  }, [manualEntryForm.values.specialtyTotal, calculatedTotalConcretePoured])

  const assignableUsers = useMemo(() => {
    const initialValue = [
      {
        value: '',
        label: 'No one',
      }
    ]

    const users = assignable.map((user) => ({
      group: user.role.toLowerCase(),
      value: user.id || user.userId,
      label: user.name,
    }))
  
    return [
      ...initialValue,
      ...users
    ]
  }, [assignable])

  const sequenceOptions = useMemo(() => {
    return generateSelectOptions(1, 20)
  }, [])

  return (
    <Container my="md" pb={!error ? 120 : 200}> {/* Add bottom padding to prevent content from being hidden behind the fixed action zone */}
      <Modal opened={locationDisclosureOpen} onClose={handleCloseLocationDisclosure} title="Location Disclosure">
        {locationPrompt?.messages ? (
          locationPrompt.messages.map((msg, id) => (
            <Text key={id} mb="md">{msg}</Text>
          ))
        ) : null}
        
        {locationPrompt?.status && locationPrompt.status === 'prompt' ? (
          <Button className={classes.button} radius="sm" size="sm" onClick={promptLocationPermission}>
            Accept
          </Button>
        ) : (
          <Button className={classes.button} radius="sm" size="sm" onClick={handleCloseLocationDisclosure}>
            Okay
          </Button>
        )}
      </Modal>

      <Title order={2} weight={500} mt="xl" mb="lg" onClick={toggleDebug}>
        {isEditMode ? 'Edit' : 'Create'} Ticket
      </Title>

      {isEditMode ? <ActionLinkToTickets /> : null}

      {/** Ticket Details */}
      <Card withBorder padding="lg" radius="md" mb="lg">
        {showDebug ? (
          <Code block>
            {JSON.stringify(ticketForm.values, null, 2)}
          </Code>
        ) : null}

        <FormHeader>Truck Details</FormHeader>

        <Select
          label="Truck"
          placeholder="Select ..."
          name="truck"
          data={truckOptions}
          dropdownPosition="bottom"
          mb="sm"
          {...getTicketInputProps('truck')}
        />

        <Select
          label="Mix Design"
          placeholder="Select ..."
          name="mixDesign"
          data={designOptions}
          dropdownPosition="bottom"
          disabled={!designOptions.length}
          {...getTicketInputProps('design')}
        />

        <Select
          label="Assigned to"
          name="assignedTo"
          data={assignableUsers}
          dropdownPosition="bottom"
          disabled={!assignable.length}
          onChange={(userId) => {
            ticketForm.setFieldValue('assignedTo', userId)
          }}
          value={getTicketInputProps('assignedTo').value}
        />

        <FormHeader>Ticket Details {isEditMode ? (<TicketStatusBadge ticket={importedTicket} />) : null}</FormHeader>

        {/* Project Meta (name) */}
        <SimpleGrid
          cols={2}
          mb="lg"
          spacing="md"
          breakpoints={[
            { maxWidth: '52rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 2, spacing: 'sm' },
            { minWidth: '92rem', cols: 2, spacing: 'md' },
          ]}
        >
          <TextInput
            label="Project Name"
            withAsterisk
            {...getTicketInputProps('project.name')}
          />
        </SimpleGrid>

        {/* Ticket Details (location, generatd id) */}
        <SimpleGrid
          cols={2}
          mb="lg"
          spacing="md"
          breakpoints={[
            { maxWidth: '52rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 2, spacing: 'sm' },
            { minWidth: '92rem', cols: 2, spacing: 'md' },
          ]}
        >
          <Autocomplete
            label="Job Location"
            placeholder={previousJobLocations.length ? 'Type to see recent locations' : 'Near tunnel 1b'}
            data={previousJobLocations || []}
            withAsterisk
            limit={7}
            {...getTicketInputProps('project.location')}
          />

          <TextInput
            label="Ticket Number"
            disabled
            classNames={{
              wrapper: viewClasses.inputDisabled,
            }}
            {...getTicketInputProps('uuid')}
          />
        </SimpleGrid>

        {/* Project Identifiers (number, id, po, zipcode) */}
        <SimpleGrid
          cols={4}
          mb="lg"
          spacing="md"
          breakpoints={[
            { maxWidth: '32rem', cols: 1, spacing: 'sm' },
            { maxWidth: '52rem', cols: 2, spacing: 'sm' },
            { maxWidth: '72rem', cols: 4, spacing: 'sm' },
            { minWidth: '92rem', cols: 4, spacing: 'md' },
          ]}
        >
          <TextInput
            label="Project Number"
            withAsterisk
            {...getTicketInputProps('project.number')}
          />

          <TextInput
            label="Project ID"
            withAsterisk
            {...getTicketInputProps('project.id')}
          />

          <TextInput
            label="PO#"
            withAsterisk
            {...getTicketInputProps('project.purchaseOrder')}
          />

          <Stack>
            <TextInput
              label="Zipcode"
              placeholder="xxxxx"
              rightSection={locationIcon}
              withAsterisk
              disabled={locationDynamic}
              classNames={{
                wrapper: viewClasses.inputDisabled,
              }}
              {...getTicketInputProps('location.zipcode')}
            />
            {locationError ? (
              <Text mt={rem(-10)} pl={rem(5)} pr={rem(5)} fz="xs" c="dimmed" ta="justify">
                We're unable to load your location at this time. Please enter a zipcode.
              </Text>
            ) : null}
          </Stack>
        </SimpleGrid>

        {/* Service Date (sequence, date, start, end) */}
        <SimpleGrid
          cols={4}
          spacing="md"
          breakpoints={[
            { maxWidth: '52rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 4, spacing: 'sm' },
            { minWidth: '92rem', cols: 4, spacing: 'md' },
          ]}
        >
          <Select
            label="Sequence #"
            placeholder="Select ..."
            name="sequence"
            data={sequenceOptions}
            mb="sm"
            {...getTicketInputProps('project.sequence')}
          />
          
          <DateInput
            label="Date"
            placeholder="Service date"
            valueFormat="MMM DD, YYYY"
            clearable
            withAsterisk
            firstDayOfWeek={0}
            {...getTicketInputProps('project.date')}
          />

          <TimeInput
            label="Start Unload"
            ref={unloadTimeRef}
            rightSection={
              <ActionIcon onClick={() => unloadTimeRef.current.showPicker()}>
                <IconClock size={rem(16)} stroke={1.5} />
              </ActionIcon>
            }
            {...getTicketInputProps('project.startTime')}
          />

          <TimeInput
            label="Finish Unload"
            ref={finishTimeRef}
            rightSection={
              <ActionIcon onClick={() => finishTimeRef.current.showPicker()}>
                <IconClock size={rem(16)} stroke={1.5} />
              </ActionIcon>
            }
            {...getTicketInputProps('project.endTime')}
          />
        </SimpleGrid>
      </Card>

      {/** Loaded Design + Config Details */}
      <Card withBorder padding="lg" radius="md"  mb="lg">
        {showDebug ? (
          <Code block>
            {JSON.stringify(confirmationForm.values, null, 2)}
          </Code>
        ) : null}

        {showDebug ? (
          <Code block>
            {JSON.stringify(confirmationForm.errors, null, 2)}
          </Code>
        ) : null}

        <FormHeader>Design Configuration</FormHeader>
        <SimpleGrid
          cols={3}
          spacing="md"
          breakpoints={[
            { maxWidth: '52rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 2, spacing: 'sm' },
            { minWidth: '92rem', cols: 3, spacing: 'md' },
          ]}
        >
          <TextInput
            label="Cement Name"
            value={activeDesign?.cement?.name || ''}
            disabled
            classNames={{
              wrapper: viewClasses.inputDisabled,
            }}
          />

          <TextInput
            label="Cement Type"
            value={cementType || ''}
            disabled
            classNames={{
              wrapper: viewClasses.inputDisabled,
            }}
          />

          <TextInput
            label="Cement Count"
            value={activeDesign?.truckConfig?.cement?.count || ''}
            disabled
            classNames={{
              wrapper: viewClasses.inputDisabled,
            }}
            {...getInputSectionText('per yard')}
          />
        </SimpleGrid>

        <SectionHeader order={4}>Aggregates</SectionHeader>
        
        {confirmationForm.errors.aggregates ? (
          <ErrorSection message={confirmationForm.errors.aggregates} />
        ) : null}

        <SimpleGrid
          cols={3}
          spacing="md"
          breakpoints={[
            { maxWidth: '52rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 2, spacing: 'sm' },
            { minWidth: '92rem', cols: 3, spacing: 'md' },
          ]}
        >
          {!designLoaded ? null : aggregates.length ? aggregates : (
            <Text c="dimmed" pl="sm">No Aggregates in design spec</Text>
          )}
        </SimpleGrid>

        <SectionHeader order={4}>Admixtures</SectionHeader>
        
        {confirmationForm.errors.admixtures ? (
          <ErrorSection message={confirmationForm.errors.admixtures} />
        ) : null}

        <SimpleGrid
          cols={3}
          spacing="md"
          breakpoints={[
            { maxWidth: '52rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 2, spacing: 'sm' },
            { minWidth: '92rem', cols: 3, spacing: 'md' },
          ]}
        >
          {!designLoaded ? null : admixtures.length ? admixtures : (
            <Text c="dimmed" pl="sm">No Admixtures in design spec</Text>
          )}
        </SimpleGrid>
      </Card>

      {/* Manual Input Entry */}
      <Card withBorder padding="lg" radius="md" mb="lg">
        {showDebug ? (
          <Code block>
            {JSON.stringify(manualEntryForm.values, null, 2)}
          </Code>
        ) : null}

        {showDebug ? (
          <Code block>
            {JSON.stringify(manualEntryForm.errors, null, 2)}
          </Code>
        ) : null}

        <FormHeader>Jobsite Inputs</FormHeader>
        <SimpleGrid
          cols={3}
          spacing="md"
          breakpoints={[
            { maxWidth: '52rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 2, spacing: 'sm' },
            { minWidth: '92rem', cols: 3, spacing: 'md' },
          ]}
        >
          <TextInput
            label="Cement Total Counts"
            type="number"
            step="1"
            withAsterisk
            disabled={!activeDesign}
            {...getManualEntryInputProps('cementCount')}
          />

          <TextInput
            type="number"
            step=".1"
            label="Water Total"
            withAsterisk
            disabled={!activeDesign}
            classNames={{
              wrapper: viewClasses.inputDisabled,
            }}
            {...getManualEntryInputProps('waterTotal')}
            {...getInputSectionText('gallons')}
          />

          <TextInput
            label="Specialty Total"
            withAsterisk
            disabled={!activeDesign}
            {...getManualEntryInputProps('specialtyTotal')}
            {...getInputSectionText('gallons')}
          />
        </SimpleGrid>
      </Card>

      {/* Calculated inputs */}
      <Card withBorder padding="lg" radius="md" mb="lg">
        <FormHeader>Calculated</FormHeader>
        <SimpleGrid
          cols={3}
          spacing="md"
          breakpoints={[
            { maxWidth: '52rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 2, spacing: 'sm' },
            { minWidth: '92rem', cols: 3, spacing: 'md' },
          ]}
        >
          <Box mb="md">
            <TextInput
              label="Total Concrete Poured"
              disabled
              classNames={{
                wrapper: viewClasses.inputDisabled,
              }}
              value={calculatedTotalConcretePoured}
              {...getInputSectionText('yards')}
            />
          </Box>

          <Box mb="md">
            <TextInput
              label="Water"
              disabled
              classNames={{
                wrapper: viewClasses.inputDisabled,
              }}
              value={calculatedWaterPerYard}
              {...getInputSectionText('per yard')}
            />
          </Box>

          <Box mb="md">
            <TextInput
              label="Specialty"
              disabled
              classNames={{
                wrapper: viewClasses.inputDisabled,
              }}
              value={calculatedSpecialtyPerYard}
              {...getInputSectionText('per yard')}
            />
          </Box>

          <Box mb="md">
            <TextInput
              label="Total Yards Billed"
              disabled
              classNames={{
                wrapper: viewClasses.inputDisabled,
              }}
              value={calculatedTotalConcretePoured}
            />
          </Box>
        </SimpleGrid>
      </Card>

      {/* Products */}
      <Card withBorder padding="lg" radius="md">
        <FormHeader>Products</FormHeader>
        <SimpleGrid
          cols={3}
          spacing="md"
          breakpoints={[
            { maxWidth: '52rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 2, spacing: 'sm' },
            { minWidth: '92rem', cols: 3, spacing: 'md' },
          ]}
        >

          {/* required initial product (generated) */}
          <Card withBorder>
            <Box mb="md">
              <Text size="xs" color="dimmed">Product Name</Text>
              <Text fw={500} size="sm">{activeDesign ? activeDesign.meta.name : '—'}</Text>
            </Box>

            <Box mb="md">
              <Text size="xs" color="dimmed">Quantity</Text>
              <Text fw={500} size="sm">{calculatedTotalConcretePoured || '—'} {'yd^3'}</Text>
            </Box>

            <Box mb="md">
              <Text size="xs" color="dimmed">Product ID</Text>
              <Text fw={500} size="sm">{activeDesign ? activeDesign.meta.mixDesignNumber : '—'}</Text>
            </Box>

            <Box mb="md">
              <Text size="xs" color="dimmed">Product Code</Text>
              <Text fw={500} size="sm">{activeDesign ? activeDesign.meta.mixDesignNumber : '—'}</Text>
            </Box>
          </Card>
        </SimpleGrid>
      </Card>

      {/* Fixed Action Zone */}
      <Paper
        shadow="md"
        p="md"
        withBorder
        sx={(theme) => ({
          position: 'fixed',
          bottom: 0,
          left: isDesktop ? desktopNavWidth : mobileNavWidth,
          right: 0,
          background: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
          zIndex: 90,
          transition: 'left 200ms ease-in-out',
        })}
      >
        <Container size="xl">
          {error ? (
            <ErrorSection message={error} />
          ) : null}

          <Flex
            gap="md"
            justify="center"
            align="center"
            direction={{ base: 'column', xs: 'row' }}
            wrap="wrap"
          >
            <DraftButton
              onClick={saveTicket}
              text={`Save Draft`}
              submitting={submitting}
            />
            <SubmitButton
              onClick={submitTicket}
              text={`${isEditMode && loadedTicket?.status !== TICKET_STATUSES.DRAFT ? 'Update' : 'Submit'} Ticket`}
              submitting={submitting}
            />
          </Flex>
        </Container>
      </Paper>
    </Container>
  );
}