import { HttpStatusCode } from 'axios'
import fetchService from '../fetch.service'

/**
 * Fetch auth key config
 * 
 * @param {*} company 
 * @returns 
 */
export const fetchAuthKeyConfig = async (company = {}) => {
  const companyId = company.id || company.companyId

  try {
    const response = await fetchService.get(`/auth/companies/${companyId}/key/config`)
    if (response.status !== HttpStatusCode.Ok || !response.result.config) {
      throw new Error(`Unable to fetch auth key config [${response.status}]`)
    }

    return {
      ok: true,
      status: 200,
      config: response.result.config,
    }
  } catch (err) {
    console.error(err)
    return {
      ok: false,
      status: err?.response?.status || 500,
    }
  }
}

/**
 * Fetch all auth keys that are associated with the company
 * 
 * @param {*} company 
 * @returns 
 */
export const fetchAuthKeysForCompany = async (company) => {
  const companyId = company.id || company.companyId

  try {
    const response = await fetchService.get(`/auth/companies/${companyId}/keys`)
    if (response.status !== HttpStatusCode.Ok || !response.result.authKeys) {
      throw new Error(`Unable to fetch auth keys for company [${response.status}]`)
    }

    return {
      status: 200,
      ok: true,
      authKeys: response.result.authKeys,
    }
  } catch (err) {
    console.error(err)
    return {
      status: err?.response?.status || 500,
      ok: false,
    }
  }
}

/**
 * Create a new auth key and associate to the company
 * 
 * @param {*} company 
 * @param {*} authKey 
 * @returns 
 */
export const createAuthKeyForCompany = async (company, authKey) => {
  const companyId = company.id || company.companyId

  try {
    const body = {
      company,
      authKey,
    }

    const response = await fetchService.post(`/auth/companies/${companyId}/keys`, { body })
    if (response.status !== HttpStatusCode.Ok || !response.result.authKey) {
      throw new Error(`Unable to add new auth key to company [${response.status}]`)
    }

    return {
      ok: true,
      status: 200,
      authKey: response.result.authKey,
    }
  } catch (err) {
    console.error(err)
    return {
      ok: false,
      status: err?.response?.status || 500,
    }
  }
}

/**
 * Update an existing auth key
 * 
 * @param {*} client 
 * @param {*} company 
 * @returns 
 */
export const updateAuthKeyInCompany = async (company, authKey) => {
  const companyId = company.id || company.companyId,
    authKeyId = authKey.id || authKey.authKeyId

  try {
    const body = {
      authKey,
    }

    const response = await fetchService.patch(`/auth/companies/${companyId}/key/${authKeyId}`, { body })
    if (response.status !== HttpStatusCode.Ok || !response.result.updated) {
      throw new Error(`Unable to update auth key [${response.status}]`)
    }

    return {
      status: 200,
      ok: true,
      updated: response.result.updated,
    }
  } catch (err) {
    console.error(err)
    return {
      status: err?.response?.status || 500,
      ok: false,
    }
  }
}

export const deleteAuthKeyFromCompany = async (company, authKey) => {
  const companyId = company.id || company.companyId,
    authKeyId = authKey.id || authKey.authKeyId

  try {
    const response = await fetchService.delete(`/auth/companies/${companyId}/key/${authKeyId}`)
    if (response.status !== HttpStatusCode.Ok || !response.result.deleted) {
      throw new Error(`Unable to delete auth key [${response.status}]`)
    }

    return {
      status: 200,
      ok: true,
      deleted: response.result.deleted,
    }
  } catch (err) {
    console.error(err)
    return {
      status: err?.response?.status || 500,
      ok: false,
    }
  }
}

