import { HttpStatusCode } from "axios"
import fetchService from "./fetch.service"

export const fetchTickets = async (ticketOwnership) => {
  if (ticketOwnership !== 'all') return await fetchService.get('/auth/tickets/me')
  return await fetchService.get('/auth/tickets')
}

export const publishTicket = async (ticket) => {
  const ticketId = ticket.id || ticket.ticketId

  try {
    const response = await fetchService.post(`/auth/ticket/${ticketId}/publish`, {})
    if (response.status !== HttpStatusCode.Ok || !(response.result.delivery && response.result.delivery.status === 'ok')) {
      throw new Error(`Unable to publish ticket [${response.status}]`)
    }

    return {
      ok: true,
      status: 200,
      authKey: response.result.authKey,
    }
  } catch (err) {
    console.error(err)
    return {
      ok: false,
      status: err?.response?.status || 500,
    }
  }
}