import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Container,
  Title,
  rem,
  ActionIcon,
  createStyles,
  Menu,
  ScrollArea,
  Box,
  Table,
  Center,
  Button,
  Text,
  // NavLink,
} from '@mantine/core'
import {
  IconDotsVertical,
  IconTrash,
  IconPlus,
  IconSettings,
  // IconLockShare,
  // IconUserEdit,
  IconCheck,
  IconExclamationCircle,
} from '@tabler/icons-react'
import { notifications } from '@mantine/notifications'

import { noop } from '../../../utils/noop'
// import { fetchClientsForCompany } from '../../tickets/modals/share-modal/actions'
// import { ManageClientModal } from '../modals/manage-client-modal'
// import { DeleteClientModal } from '../modals/delete-client-modal'
// import { AddClientModal } from '../modals/add-client-modal'
// import { EditClientModal } from '../modals/edit-client-modal'
import { Link } from 'react-router-dom'
import { deleteAuthKeyFromCompany, fetchAuthKeyConfig, fetchAuthKeysForCompany } from '../../../services/company/auth-keys.service'
import { CreateAuthKeyModal } from '../modals/create-auth-key-modal'

const useStyles = createStyles((theme) => ({
  card: {
    overflow: 'inherit',
  },
  button: {
    borderRadius: theme.radius.sm,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
    cursor: 'pointer',
    height: rem('3.25rem'),
    maxWidth: rem('13rem'),
  },
  tableActionRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tableHeader: {
    backgroundColor: '#ffffff',
  },
  tableHeadCol: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#ffffff',
  },
  tableHeadColIcon: {
    zIndex: 1,
  },
}))

const ManageMenu = (props) => {
  const {
    label = '',
    authKey = {},
    children,
    onDelete = noop,
  } = props

  const iconSize = 16

  if (!authKey) return null

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{label || authKey.label}</Menu.Label>

        <Menu.Item
          onClick={onDelete}
          icon={<IconTrash size={iconSize} />}
        >
          Delete Key
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

export const ManageAuthKeysForm = (props) => {
  const { company } = props,
    { classes, cx } = useStyles(),
    [ authKeys, setAuthKeys ] = useState(null),
    [showCreateModal, setShowCreateModal] = useState(false),
    [config, setConfig] = useState({})

  useEffect(() => {
    if (!company) return

    fetchAuthKeyConfig(company)
      .then((response) => {
        setConfig(response.config)
      })
      .catch((err) => {
        console.warn('Failed to fetch Auth Key config', err)
        setConfig({})
      })
  }, [company])

  
  const authKeyTypes = useMemo(() => {
    if (!config || !config.types) return []

    return config.types.reduce((types, type) => {
      if (types[type.value]) return types
      types[type.value] = type.label

      return types
    }, {})
  }, [config])

  const hydrateCompanyAuthKeys = useCallback(() => {
    fetchAuthKeysForCompany(company)
      .then((response) => {
        if (!response.ok) {
          setAuthKeys(null)
        } else {
          setAuthKeys(response.authKeys)
        }
      })
  }, [company])

  useEffect(() => {
    if (!company) return
    hydrateCompanyAuthKeys()
  }, [company, hydrateCompanyAuthKeys])

  const deleteAuthKey = async (authKey) => {
    const result = await deleteAuthKeyFromCompany(company, authKey)
    if (result.ok) {
      hydrateCompanyAuthKeys();
      notifications.show({
        id: 'authkey::delete::success',
        color: 'teal',
        title: 'Auth Key Removed',
        message: `${authKey.label} has been removed from your business profile.`,
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
        autoClose: true,
      })
    } else {
      notifications.show({
        id: 'authkey::delete::error',
        color: 'red',
        title: `Unable to remove Auth Key`,
        message: `We hit a snag removing the key '${authKey.label}'. Please try again or contact support.`,
        icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
        autoClose: false,
      })
    }
  }

  const tableRows = (authKeys || []).map((authKey, index) => {
    const ActionMenu = (props) => {
      const methods = {
        // onEdit: () => setEditTarget(client),
        // onManageAccess: () => setManageTarget(client),
        onDelete: () => deleteAuthKey(authKey),
      }

      return (
        <ManageMenu authKey={authKey} {...methods}>
          <ActionIcon variant="transparent" color="blue" radius="xl" size="sm" aria-label="Options">
            <IconDotsVertical />
          </ActionIcon>
        </ManageMenu>
      )
    }

    return (
      <tr key={authKey.id}>
        <td>{authKeyTypes[authKey.type] || authKey.type}</td>
        <td>{authKey.label}</td>
        <td>{authKey.preview || ''}</td>
        <td><Center><ActionMenu /></Center></td>
      </tr>
    )
  })

  const onCreateSuccess = (authKey) => {
    setShowCreateModal(false)
    notifications.show({
      id: 'authkey::create::success',
      color: 'teal',
      title: 'Auth Key Added',
      message: `Successfully added ${authKey.label} to your business profile.`,
      icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
      loading: false,
      autoClose: false,
    })

    hydrateCompanyAuthKeys()
  }

  const onCreateCancel = () => {
    setShowCreateModal(false)
  }

  return (
    <Container p="0">
      <Title order={2} weight={500} mt="xl" mb="lg">Manage Auth Keys</Title>
      
      <CreateAuthKeyModal
        opened={showCreateModal}
        onSuccess={onCreateSuccess}
        onCancel={onCreateCancel}
      />

      <Box mb="sm" className={classes.tableActionRow}>
        <Button
          onClick={() => setShowCreateModal(!showCreateModal)}
          leftIcon={<IconPlus size="1.125rem" />}
          pr={12}
        >
          Add auth key
        </Button>
      </Box>

      <ScrollArea mb="xl" h={400}>
        <Table highlightOnHover withColumnBorders withBorder striped>
          <thead className={classes.tableHeader}>
            <tr>
              <th className={classes.tableHeadCol}>Type</th>
              <th className={classes.tableHeadCol}>Label</th>
              <th className={classes.tableHeadCol}>Value</th>
              <th className={cx(classes.tableHeadCol, classes.tableHeadColIcon)}>
                <Center><IconSettings /></Center>
              </th>
            </tr>
          </thead>
          {!authKeys || !authKeys.length ? (
            <Text mb="md" pl="sm" mt="md">
              No auth keys associated with your business. <Link onClick={() => setShowCreateModal(!showCreateModal)}>Add a new auth key</Link>
            </Text>
          ) : (
            <tbody>
              {tableRows}
            </tbody>
          )}
        </Table>
      </ScrollArea>
    </Container>
  )
}
