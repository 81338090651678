
import { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import browserStorageService, { STORAGE_KEY } from '../../services/browser-storage.service'
import { userCanModify } from '../users/helper'
import { ManageAuthKeysForm } from './form/manage-auth'
import { Text } from '@mantine/core'

export const transpile = (importedSpec = null) => {
  console.log('import', importedSpec)
  if (!importedSpec || importedSpec === undefined) return null

  const {
    name,
    phone,
    address,
    city,
    state,
    zipcode,
    status,
  } = importedSpec

  return {
    name,
    phone,
    address,
    city,
    state,
    zipcode,
    status,
  }
}

export const ManageCompanyAuthKeysView = () => {
  const importedCompany = useLoaderData()
  const [canModify, setCanModify] = useState(false)

  useEffect(() => {
    browserStorageService.getItem(STORAGE_KEY.ROLE)
      .then((role) => {
        setCanModify(userCanModify(role))
      })
  }, [setCanModify])

  return (
    <>
      {canModify ? (
        <>
          <ManageAuthKeysForm company={importedCompany} />
        </>
      ) : (
        <Text>
          It looks like you lack permissions to access or manage business details.
          Please contact your admin or support if you find this to be incorrect.
        </Text>
      )}
    </>
  )
}
