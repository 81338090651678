import { useMemo, useState, useEffect, useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  Flex,
  Code,
  rem,
  createStyles,
  Text,
  ActionIcon,
  Box,
  UnstyledButton,
} from '@mantine/core'
import {
  IconMenu2,
  IconChartPie,
  IconDroplet,
  IconFileChart,
  IconTruck,
  IconUsers,
  IconFileDescription,
  IconLayout2,
  IconRoad,
  IconSettings,
  IconLogout,
  IconHelp,
} from '@tabler/icons-react'
import { useOs } from '@mantine/hooks'
import { ReactComponent as Logo } from '../../static/econcretely-icon.svg'
import { LinksGroup } from './navbar-link-group'
import classes from './navbar-nested.module.scss'
import { useNavbarStyles, navbarStyleIconStroke} from './styles'
import browserStorageService, { STORAGE_KEY } from '../../services/browser-storage.service'
import { userCanModify } from '../users/helper'
import appPackage from '../../../package.json'

const BASE_PATH = '/dashboard'
const APP_LINKS = [
  {
    label: 'Dashboard',
    icon: IconLayout2,
    link: '/',
  },
  {
    label: 'Manage Mix Designs',
    icon: IconChartPie,
    // icon: IconDroplet,
    // icon: IconFileChart,
    initiallyOpened: false,
    link: '/designs',
    links: [
      { label: 'Create', link: '/design/create' },
      { label: 'View All', link: '/designs' },
    ],
    requireModifyAccess: true,
  },
  {
    label: 'Manage Fleet',
    icon: IconTruck,
    initiallyOpened: false,
    link: '/fleet',
    links: [
      { label: 'Create Truck', link: '/truck/create' },
      { label: 'View All', link: '/fleet' },
    ],
    requireModifyAccess: true,
  },
  {
    label: 'Manage Tickets',
    icon: IconFileDescription,
    initiallyOpened: false,
    link: '/tickets',
    links: [
      { label: 'Create Ticket', link: '/ticket/create' },
      { label: 'View All', link: '/tickets' },
    ],
  },
  {
    label: 'Manage Users',
    icon: IconUsers,
    // initiallyOpened: true,
    link: '/users',
    requireModifyAccess: true,
    // links: [
    //   { label: 'Create User', link: '/user/create' },
    //   { label: 'View All', link: '/users' },
    // ],
  },
  {
    label: 'Company Settings',
    icon: IconRoad,
    link: '/company',
    requireModifyAccess: true,
    initiallyOpened: false,
    links: [
      { label: 'Clients', link: '/company/clients' },
      { label: 'Products', link: '/company/products' },
      { label: 'Auth Keys', link: '/company/auth-keys' },
    ],
  },
]

const FOOTER_LINKS = [
  {
    label: 'Account Settings',
    icon: IconSettings,
    link: '/settings',
  },
  {
    label: 'Support',
    icon: IconHelp,
    link: '/contact',
    root: true,
  },
  {
    label: 'Logout',
    icon: IconLogout,
    link: '/logout',
    root: true,
  },
]

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
  },

  version: {
    backgroundColor: theme.fn.lighten(
      theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
      0.1
    ),
    color: theme.white,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
  },

  header: {
    paddingBottom: theme.spacing.md,
    marginBottom: `calc(${theme.spacing.md} * 1.5)`,
    borderBottom: `${rem(1)} solid ${theme.fn.lighten(
      theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
      0.1
    )}`,
  },

  footer: {
    marginTop: theme.spacing.lg,
    paddingTop: theme.spacing.lg,
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    borderTop: '1px solid #363636',
  },

  // margin-left: calc(var(--mantine-spacing-md) * -1);
  // margin-right: calc(var(--mantine-spacing-md) * -1);
  // // border-top: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  // border-top: 1px solid #363636;


  heading: {
    color: 'var(--ly-sidebar)',
    cursor: 'default',

    '::selection': {
      color: 'var(--ly-sidebar)',
      background: 'transparent',
    },
  },

  menuIconBtn: {
    color: '#ffffff',
    opacity: 1,
    transition: 'opacity 200ms ease-in-out',

    [theme.fn.largerThan('sm')]: {
      opacity: 0,
    },
  },

  menuIconBtnClosed: {
    marginLeft: '-5px',
  },

  link: {
    color: '#f9af42',
    position: 'relative',
    textDecoration: 'none',
  },
}));

const AppLinks = (props) => {
  const {
    menuOpen = false,
    toggleMenu = () => {},
    isDesktop = false,
    canModify = false,
  } = props

  const viewableLinks = useMemo(() => {
    return APP_LINKS.filter((link) => {
      if (!link.requireModifyAccess) return link
      else return canModify
    })
  }, [canModify])

  return viewableLinks.map((item, index) => (
    <LinksGroup
      {...item}
      key={index}
      basePath={BASE_PATH}
      className="link-group"
      menuOpen={menuOpen}
      toggleMenu={toggleMenu}
      isDesktop={isDesktop}
    />
  ))
}

export const NavbarNested = (props) => {
  const {
    toggleMenu,
    active,
    isDesktop,
  } = props

  const {classes: styles, cx} = useStyles(),
    { classes: navbarStyles } = useNavbarStyles(),
    [canModify, setCanModify] = useState(false),
    [timeoutId, setTimeoutId] = useState(null),
    os = useOs(),
    navigate = useNavigate(),
    handleRedirect = (path, root) => {
      if (root) {
        navigate(path)
      } else {
        navigate(`${BASE_PATH}${path}`)
      }
    }

  // console.log('OS', os)

  useEffect(() => {
    browserStorageService.getItem(STORAGE_KEY.ROLE)
      .then((role) => {
        setCanModify(userCanModify(role))
      })
  }, [setCanModify, browserStorageService.sessionStarted])

  const triggerNavOpen = useCallback(() => {
    if (['ios', 'android'].includes(os)) return
    if (active) return
    const id = setTimeout(() => {
      toggleMenu()
    }, 500)
    setTimeoutId(id)
  }, [active])

  const clearTriggerNav = useCallback(() => {
    clearTimeout(timeoutId)
    if (active && timeoutId) {
      toggleMenu()
      setTimeoutId(null)
    }
  }, [active, timeoutId])

  const openNav = () => {
    clearTimeout(timeoutId)
    setTimeoutId(null)
    toggleMenu()
  }

  return (
    <nav
      className={classes.navbar}
      onMouseEnter={triggerNavOpen}
      onMouseLeave={clearTriggerNav}
    >
      <div className={classes.header}>
        <Flex justify={active ? 'space-between' : 'start'} align="center" mb="xs">
          <Box className="navbar-logo">
            <Logo width={rem(45)} />
          </Box>
          <Text className={`${styles.heading} app-name`}>eTicketing</Text>
          <ActionIcon onClick={openNav} className={cx(styles.menuIconBtn, { [styles.menuIconBtnClosed]: !active })} color="red" size="lg" radius="xl" variant="transparent">
            <IconMenu2 size="28" stroke={1.5} />
          </ActionIcon>
        </Flex>
        <Flex className="app-version">
          <Code className={styles.version} mr="sm">v{appPackage.version}</Code>
          <Code className={styles.website}>econcrete.ly</Code>
        </Flex>
      </div>

      <div className={classes.links}>
        <div className={`${classes.linksInner} links-inner`}>
          <AppLinks menuOpen={active} toggleMenu={toggleMenu} isDesktop={isDesktop} canModify={canModify} />
        </div>
      </div>

      <div className={styles.footer}>
        {FOOTER_LINKS.map(({ label, icon: Icon, link, root = false}, index) => (
          <Box key={index} className={cx(navbarStyles.linkBox, navbarStyles.linkBoxHover)} onClick={() => handleRedirect(link, root)}>
            <UnstyledButton className={navbarStyles.navIcon}>
              <Icon size={24} stroke={navbarStyleIconStroke} />
            </UnstyledButton>
            <Box ml="md" className={`${navbarStyles.link} link-group__label`}>{label}</Box>
          </Box>
        ))}

        {active ? (
          <Box ta="left" ml="md" mt="md" size="xs">
            <Text component="span" mr="sm" color="white" size="sm">Having issues?</Text>
            <Link className={styles.link} ml="sm" to="mailto:info@econcrete.ly">
              <Text size="sm">Contact info[@]econcrete.ly</Text>
            </Link>
          </Box>
        ) : null}
      </div>
    </nav>
  )
}
