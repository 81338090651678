import React from 'react'
import { create } from 'zustand'
import { useMediaQuery } from '@mantine/hooks'

const desktopNavWidth = '17rem'
const mobileNavWidth = '4rem'

export const useNavbarStore = create((set) => ({
  activeMenu: true,
  desktopNavWidth,
  mobileNavWidth,
  isDesktop: false,
  toggleMenu: () => { console.log('toggle menu!'); set((state) => ({ activeMenu: !state.activeMenu })) },
  setIsDesktop: (isDesktop) => set({ isDesktop }),
}))

// Custom hook to update isDesktop based on media query
export const useUpdateIsDesktop = () => {
  const setIsDesktop = useNavbarStore((state) => state.setIsDesktop)
  const isDesktop = useMediaQuery('(min-width: 48em)')

  React.useEffect(() => {
    setIsDesktop(isDesktop)
  }, [isDesktop, setIsDesktop])
}
