
import { useEffect, useState } from 'react'
// import { useLoaderData } from 'react-router-dom'
// import { ManageClientsForm } from './form/manage-clients'
import browserStorageService, { STORAGE_KEY } from '../../services/browser-storage.service'
import { userCanModify } from '../users/helper'
import { Container, Text, Title } from '@mantine/core'

export const transpile = (importedSpec = null) => {
  if (!importedSpec || importedSpec === undefined) return null

  const {
    name,
    phone,
    address,
    city,
    state,
    zipcode,
    status,
  } = importedSpec

  return {
    name,
    phone,
    address,
    city,
    state,
    zipcode,
    status,
  }
}

export const ManageCompanyProductsView = () => {
  // const importedCompany = useLoaderData()
  const [canModify, setCanModify] = useState(false)

  useEffect(() => {
    browserStorageService.getItem(STORAGE_KEY.ROLE)
      .then((role) => {
        setCanModify(userCanModify(role))
      })
  }, [setCanModify])

  return (
    <>
      {canModify ? (
        <>
          <Container p="0">
            <Title order={2} weight={500} mt="xl" mb="md">Configurable Products</Title>
            <Text>Manage products that can be added and configured in each ticket here.</Text>

            <Title mt="xl" order={3}>Coming soon.</Title>
          </Container>
        </>
      ) : (
        <Text>
          It looks like you lack permissions to access or manage business details.
          Please contact your admin or support if you find this to be incorrect.
        </Text>
      )}
    </>
  )
}
