import { useEffect, useMemo, useRef, useState } from 'react'
import { HttpStatusCode } from 'axios'
import { z } from 'zod'
import {
  Button,
  // rem,
  // createStyles,
  TextInput,
  SimpleGrid,
  UnstyledButton,
  Text,
  Flex,
  Loader,
  Box,
  Select,
} from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import {
  IconAlertCircle,
} from '@tabler/icons-react'

import { createAuthKeyForCompany, fetchAuthKeyConfig } from '../../services/company/auth-keys.service'
import { noop } from '../../utils/noop'
import browserStorageService, { STORAGE_KEY } from '../../services/browser-storage.service'

// const useStyles = createStyles((theme) => ({
//   button: {
//     borderRadius: theme.radius.sm,
//     padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
//     cursor: 'pointer',
//     height: rem('3.25rem'),
//     maxWidth: rem('13rem'),
//   },
//   tableActionRow: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//   },
//   tableHeader: {
//     backgroundColor: '#ffffff',
//   },
//   tableHeadCol: {
//     position: 'sticky',
//     top: 0,
//     backgroundColor: '#ffffff',
//   },
//   tableHeadColIcon: {
//     zIndex: 1,
//   },
// }))

const schemaAggregate = z.object({
  type: z.string().min(1, { message: 'You must select the type of Auth Key' }),
  label: z.string().min(1, { message: 'That label looks short' }),
  value: z.string().min(1, { message: 'That auth key value looks short' }),
})

export const AuthKeyForm = (props) => {
  const {
    // header = 'Auth Key',
    saveLabel = 'Save',
    hideTicketDetails = false,
    prefill = null,
    onSuccess = noop,
    onCancel = noop,
  } = props

  const [sessionCompany, setCompany] = useState({}),
    [errorMessage, setError] = useState(''),
    [processing, setProcessing] = useState(false),
    [config, setConfig] = useState({}),
    // { classes, cx } = useStyles(),
    prefilled = useRef(false)

  // Load company data
  useEffect(() => {
    browserStorageService.getItem(STORAGE_KEY.SESSION)
      .then((session) => {
        setCompany(session.company)
      })

    fetchAuthKeyConfig()
  }, [])

  useEffect(() => {
    if (!sessionCompany) return

    fetchAuthKeyConfig(sessionCompany)
      .then((response) => {
        setConfig(response.config)
      })
      .catch((err) => {
        console.warn('Failed to fetch Auth Key config', err)
        setConfig({})
      })
  }, [sessionCompany])

  
  const authKeyTypes = useMemo(() => {
    if (!config || !config.types) return []

    return config.types.map((type) => ({
      label: type.label,
      value: type.value,
    }))
  }, [config])

  const form = useForm({
    initialValues: {
      label: '',
      value: '',
    },

    validate: zodResolver(schemaAggregate.required()),
  })

  const handleSubmit = async () => {
    setError('')
    setProcessing(true)
    form.validate()
    if (!form.isValid()) {
      setError('Please double check the information above and try again')
      setProcessing(false)
      return false
    }

    try {
      const response = await createAuthKeyForCompany(sessionCompany, { ...form.values })
      if (!response.ok && response.status === HttpStatusCode.Conflict) {
        setError('Whoops! It looks like this auth key already exists for your business. Please double check your information or refresh the page.')
        return
      } else if (!response.ok || !response.authKey) {
        setError('We hit a snag adding this Auth Key to your business. Please try again later or contact support.')
        return
      }

      handleSuccess(response.authKey)
      return true
    } catch (err) {
      setError('Unable to process this request at this time. Please try again later or contact support.')
      return false
    } finally {
      setProcessing(false)
    }
  }

  const handleSuccess = (authKey) => {
    form.reset()
    setProcessing(false)
    onSuccess(authKey)
  }

  const handleCancel = () => {
    form.reset()
    setProcessing(false)
    onCancel()
  }

  useEffect(() => {
    if (!prefill) return
    if (prefilled.current) return

    Object.keys(prefill).forEach((key) => {
      if (prefill[key].length) {
        form.setFieldValue(key, prefill[key])
      }
    })

    // prevent repeated refills
    prefilled.current = true
  }, [prefill, form])

  return (
    <Box>
      <SimpleGrid
        cols={1}
        mt={!hideTicketDetails ? "lg" : ""}
        spacing="md"
        // breakpoints={[
        //   { maxWidth: '30rem', cols: 1, spacing: 'sm' },
        //   { maxWidth: '72rem', cols: 2, spacing: 'sm' },
        // ]}
      >
        <Select
          label="Auth Type"
          name="form.type"
          placeholder="Select Auth Key Type"
          data={authKeyTypes}
          {...form.getInputProps('type')}
        />
        
        <TextInput
          label="Auth Label"
          name="form.label"
          placeholder="Haul Hub API"
          size="sm"
          withAsterisk
          {...form.getInputProps('label')}
        />

        <TextInput
          label="Auth Value"
          name="form.value"
          placeholder="X1234-Y5678"
          size="sm"
          withAsterisk
          {...form.getInputProps('value')}
        />
      </SimpleGrid>

      {errorMessage ? (
        <Flex mb="md">
          <IconAlertCircle size="1.5rem" color="red" />
          <Text fz="sm" ml="md" color="red">{errorMessage}</Text>
        </Flex>
      ) : null}

      <Flex
        mih={50}
        gap="md"
        justify="center"
        align="center"
        direction="row"
        wrap="wrap"
      >
        <UnstyledButton onClick={handleCancel} disabled={processing}>
          Cancel
        </UnstyledButton>
        <Button onClick={handleSubmit} disabled={processing}>
          {processing ? (
            <Loader color="blue" size="sm" />
          ) : saveLabel}
        </Button>
      </Flex>
    </Box>
  )
}
