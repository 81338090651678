import dayjs from 'dayjs'

export const TICKET_STATUSES = {
  DRAFT: 'DRAFT',
  IN_REVIEW: 'IN_REVIEW',
  APPROVED: 'APPROVED',
  PUBLISHED: 'PUBLISHED',
  VOID: 'VOID',
}

export const TICKET_STATUS_BADGE = {
  DRAFT:      { label: 'DRAFT', color: 'gray' },
  IN_REVIEW:  { label: 'IN REVIEW', color: 'yellow' },
  APPROVED:   { label: 'APPROVED', color: 'green' },
  PUBLISHED:  { label: 'PUBLISHED', color: 'blue' },
  VOID:       { label: 'VOID', color: 'red' },
}

export const CEMENT_TYPES = {
  // Portlands
  'portland.type1':   'Portland Type I',
  'portland.type1l':  'Portland Type I (L)',
  'portland.type2':   'Portland Type II',
  'portland.type3':   'Portland Type III',
  'portland.type4':   'Portland Type IV',
  'portland.type5':   'Portland Type V',

  // Specialties
  'specialty.calciumAluminate':       'Calcium Aluminate',
  'specialty.calciumSulfoaluminate':  'Calcium Sulfoaluminate',
  'specialty.ctsRapidSet':            'CTS Rapid Set',
}

export const formatTimestamp = (timestampStr, { showTime = true } = {}) => {
  const format = `MMM DD YYYY${showTime ? ' h:mma' : ''}`
  return dayjs(timestampStr).format('MMM DD YYYY h:mma')
}

export const formatUnix = (unix, { showTime = true } = {}) => {
  const format = `MMM DD YYYY${showTime ? ' h:mma' : ''}`
  return dayjs.unix(unix).format(format)
}

export const parseInputFixed = (input) => {
  return parseFloat(Number(input).toFixed(3))
}
