import { rem } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { StatusCodes } from 'http-status-codes'
import { IconCheck, IconExclamationCircle } from '@tabler/icons-react'
import fetchService from '../../services/fetch.service'
import { TICKET_STATUSES } from './helper'
import downloadService from '../../services/download.service'

export const approveTicket = async (ticket, notificationId) => {
  const ticketId = ticket.id || ticket.meta.id,
    ticketUid = ticket.ticketUid || ticket.meta.ticketUid,
    ticketName = ticket.name || ticket.meta.name,
    nId = notificationId ? notificationId : `action.ticket.${ticketId}.approve`
   
  notifications.show({
    id: nId,
    loading: true,
    title: 'Approving Ticket',
    autoClose: false,
    withCloseButton: false,
  })

  try {
    const body = { status: TICKET_STATUSES.APPROVED },
      submitted = await fetchService.patch(`/auth/ticket/${ticketId}`, { body })

    if (submitted.status === StatusCodes.OK) {
      notifications.update({
        id: nId,
        color: 'teal',
        title: `Ticket ${ticketUid} was approved!`,
        message: `Successfully approved '${ticketName}'`,
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
      })

      return true
    }
  } catch (err) {
    console.error('Unable to approve ticket', err)
    notifications.update({
      id: nId,
      color: 'red',
      title: 'Unable to approve ticket',
      message: 'We encountered an error approving your ticket. Please try again or contact support.',
      icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
      loading: false,
      autoClose: false,
    })
  }

  return false
}

export const publishTicket = async (ticket, notificationId) => {
  const ticketId = ticket.id || ticket.meta.id,
    ticketUid = ticket.ticketUid || ticket.meta.ticketUid,
    nId = notificationId ? notificationId : `action.ticket.${ticketId}.approve`
   
  notifications.show({
    id: nId,
    loading: true,
    title: 'Publishing Ticket to Haul Hub',
    autoClose: false,
    withCloseButton: false,
  })

  try {
    const submitted = await fetchService.post(`/auth/ticket/${ticketId}/publish`, {})

    if (submitted.status === StatusCodes.OK) {
      notifications.update({
        id: nId,
        color: 'teal',
        title: `Ticket published to "Haul Hub"`,
        message: `Ticket [${ticketUid}] was successfully shared with Haul Hub.`,
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
      })

      return true
    }
  } catch (err) {
    console.error('Unable to publish ticket', err)
    notifications.update({
      id: nId,
      color: 'red',
      title: 'Unable to publish this ticket',
      message: `We hit a snag while attempting to publish this ticket [${ticketUid}]. Please try again or contact support.`,
      icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
      loading: false,
      autoClose: false,
    })
  }

  return false
}

export const submitTicket = async (ticket, notificationId) => {
  const ticketId = ticket.id || ticket.meta.id,
    ticketUid = ticket.ticketUid || ticket.meta.ticketUid,
    ticketName = ticket.name || ticket.meta.name,
    nId = notificationId ? notificationId : `action.ticket.${ticketId}.submit`

  notifications.show({
    id: nId,
    loading: true,
    title: 'Submitting Ticket for Review',
    autoClose: false,
    withCloseButton: false,
  })

  try {
    const body = { status: TICKET_STATUSES.IN_REVIEW },
      submitted = await fetchService.patch(`/auth/ticket/${ticketId}`, { body })

    if (submitted.status === StatusCodes.OK) {
      notifications.update({
        id: nId,
        color: 'teal',
        title: `Ticket ${ticketUid} was submitted for approval!`,
        message: `'${ticketName}' is now ready to be approved`,
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
      })

      return true
    }
  } catch (err) {
    console.error('Unable to submit ticket', err)

    let errorMessage = 'We encountered an error submitting your ticket. Please try again or contact support.'
    if (err?.response.status === 422) {
      errorMessage = 'This ticket could not be submitted for approval. Some required fields are missing, please review this ticket and try submitting for approval again.'
    }

    notifications.update({
      id: nId,
      color: 'red',
      title: 'Unable to submit ticket',
      message: errorMessage,
      icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
      loading: false,
      autoClose: false,
    })
  }

  return false
}

export const voidTicket = async (ticket, notificationId) => {
  const ticketId = ticket.id || ticket.meta.id,
    ticketUid = ticket.ticketUid || ticket.meta.ticketUid,
    ticketName = ticket.name || ticket.meta.name,
    nId = notificationId ? notificationId : `action.ticket.${ticketId}.void`

  notifications.show({
    id: nId,
    loading: true,
    title: 'Voiding Ticket',
    autoClose: false,
    withCloseButton: false,
  })

  try {
    const body = { status: TICKET_STATUSES.VOID },
      submitted = await fetchService.delete(`/auth/ticket/${ticketId}`, { body })

    if (submitted.status === StatusCodes.OK) {
      notifications.update({
        id: nId,
        color: 'teal',
        title: `Ticket ${ticketUid} was voided`,
        message: `'${ticketName}' has now been voided`,
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
      })

      return true
    }
  } catch (err) {
    console.error('Unable to void ticket', err)
    const errorMessage = (err?.response?.status === 404)
      ? `Your Ticket "${ticketUid}" was not found and could not be voided. Please try again, or refresh the page as it could have already been voided.`
      : `We ran into an issue trying to void "${ticketUid}" you can refresh the page and try again, or contact support.${err?.response?.data?.error?.message ? ` [${err.response.data.error.message}]` : ''}`

    notifications.update({
      id: nId,
      color: 'red',
      title: 'Unable to void ticket',
      message: errorMessage,
      icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
      loading: false,
      autoClose: false,
    })
  }

  return false
}

export const viewTicketPDF = (ticket) => {
  const storedFile = ticket.storedFile || ticket.meta.storedFile
  if (storedFile) {
    window.open(storedFile, '_blank')
    return
  }

  console.warn('No stored file available', ticket)
}

export const downloadTicketPDF = (ticket) => {
  const ticketUid = ticket.ticketUid || ticket.meta.ticketUid
  if (ticketUid) {
    downloadService.download(`ticket/${ticketUid}`)
    return
  }

  console.warn('No ticketUid', ticket)
}
