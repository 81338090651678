import { useEffect } from 'react'
// import { Notifications } from '@mantine/notifications'
import { createStyles } from '@mantine/core'
import { Outlet, useNavigate } from 'react-router-dom'
import { NavbarNested } from './navbar/navbar-nested'
import fetchService from '../services/fetch.service'
import browserStorageService from '../services/browser-storage.service'
import { useNavbarStore, useUpdateIsDesktop } from '../stores/navbar.store'

// const AUTOCLOSE_NOTIFICATION = 10 * 1000

const useStyles = ({ mobileNavWidth, desktopNavWidth }) => createStyles((theme) => ({
  aside: {
    width: mobileNavWidth,
    transition: 'width 200ms ease-in-out',
    position: 'fixed',
    left: 0,
    top: 0,
    height: '100%',
    paddingBottom: theme.spacing.md,
    zIndex: 100,

    /**
     * Simplify media query writing with theme functions
     * xs: 36em
     * sm: 48em 768px
     * md: 62em
     * lg: 75em
     * xl: 88em
     */
    [theme.fn.largerThan('sm')]: {
      width: desktopNavWidth,
    },
  },
  asideOpen: {
    width: desktopNavWidth,
  },
  main: {
    marginLeft: mobileNavWidth,

    [theme.fn.largerThan('sm')]: {
      marginLeft: desktopNavWidth,
    },
  },
}));

export const DashboardView = () => {
  const { activeMenu, toggleMenu, isDesktop, desktopNavWidth, mobileNavWidth } = useNavbarStore()
  const { classes, cx } = useStyles({ desktopNavWidth, mobileNavWidth })()
  const navigate = useNavigate()

  // Update isDesktop in the store
  useUpdateIsDesktop()

  // force menu open when desktop mode, close menu when not
  useEffect(() => {
    if (isDesktop && !activeMenu) {
      toggleMenu()
    } else if (!isDesktop && activeMenu) {
      toggleMenu()
    }
  }, [isDesktop])

  useEffect(() => {
    const loadCurrentUser = async () => {
      const { result } = await fetchService.post('/auth/me')
      return result
    }

    loadCurrentUser()
      .then(async ({ user, preferences }) => {
        if (!browserStorageService.initialized) {
          browserStorageService.initialize(user.userId)
          await browserStorageService.createSession(user, { preferences })
        } else {
          await browserStorageService.updateSession(user, { preferences })
        }

        if (user.flags.blocked) {
          navigate('/logout')
        }
      })
      .catch((err) => {
        console.warn('Unable to load current user details', err)
      })
  }, [navigate])

  const handleToggleMenu = () => {
    // console.log('handleToggleMenu', {isDesktop, activeMenu, desktopNavWidth, mobileNavWidth})
    toggleMenu()
    // if (!isDesktop) {
    //   toggleMenu()
    // }
  }

  return (
    <div className="wrapper">
      <aside className={cx(classes.aside, { [classes.asideOpen]: activeMenu, 'is-active': activeMenu })}>
        <NavbarNested isDesktop={isDesktop} active={activeMenu} toggleMenu={handleToggleMenu} />
      </aside>

      <main className={cx(classes.main, { 'is-active': activeMenu })}>
        <div className="wrapper-inner">
          <Outlet />
        </div>
      </main>
    </div>
  );
}
