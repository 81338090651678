import React from 'react';
import { Code, Accordion, Text, Box } from '@mantine/core';

const JSONViewer = ({ data }) => {
  const renderJSONNode = (node, key = '') => {
    if (typeof node === 'object' && node !== null) {
      const isArray = Array.isArray(node);
      const items = Object.entries(node).map(([k, v]) => ({
        key: isArray ? `[${k}]` : k,
        value: v,
      }));

      return (
        <Accordion.Item value={key} key={key}>
          <Accordion.Control>
            <Text weight={500} color="blue">
              {key} {isArray ? '(Array)' : '(Object)'}
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            <Accordion multiple>
              {items.map(item => renderJSONNode(item.value, item.key))}
            </Accordion>
          </Accordion.Panel>
        </Accordion.Item>
      );
    } else {
      return (
        <Box key={key} mb="xs">
          <Text>
            <Text component="span" weight={500} color="blue">{key}:</Text>{' '}
            <Code>{JSON.stringify(node)}</Code>
          </Text>
        </Box>
      );
    }
  };

  return (
    <Accordion multiple>
      {renderJSONNode(data, 'Root')}
    </Accordion>
  );
};

export default JSONViewer;